<template>
<div>
	<div style="width: 100%;;">

		<div>



			<!-- <el-input @change="show=false" v-show="show"  v-model="text"/> -->
			<van-notice-bar  color="#1989fa" scrollable background="#ecf9ff" left-icon="info-o">
			  <span>2023年度，学习成长分数可以抵扣中长期激励中的学习成长（规则同七星考核中学习成长细则），诲人不倦不可以抵扣</span>
			</van-notice-bar>
	


		</div>


		<div style="float: left;  ">
			
			<el-dropdown @command="postvideo">
			    <span class="el-dropdown-link">
			      积分查看<el-icon class="el-icon--right"><arrow-down /></el-icon>
			    </span>
			    <template #dropdown>
			      <el-dropdown-menu  >
			        <el-dropdown-item command="a">学习成长值:{{scorelist.score}}</el-dropdown-item>
			        <el-dropdown-item command="b">诲人不倦值:{{scorelist.points}}</el-dropdown-item>
			        <el-dropdown-item command="c">可用学习成长值:{{scorelist.use_score}}</el-dropdown-item>
			        <el-dropdown-item command="d">可用诲人不倦值:{{scorelist.use_points}}</el-dropdown-item>
			        <el-dropdown-item command="e">视频观看次数:{{scorelist.volume}}</el-dropdown-item>
			      </el-dropdown-menu>

			    </template>
			  </el-dropdown>
		</div>
<!--    <p>视频播放量{{}}</p>-->
		<!-- <div style="float: right; ">
			<el-dropdown @command="postvideo">
			    <span class="el-dropdown-link">
			      已观看过的视频<el-icon class="el-icon--right"><arrow-down /></el-icon>
			    </span>
			    <template #dropdown>
			      <el-dropdown-menu  >
			        <el-dropdown-item v-for="i in infolist" :command="i.id">{{i.name}}</el-dropdown-item>
			      </el-dropdown-menu>
			    </template>
			  </el-dropdown>

		</div> -->

	</div>
<!-- **************************************************** -->

	<!-- 隐藏下载nodownload  隐藏倍速noplaybackrate -->

		<!-- controlsList="noplaybackrate" -->

	<div style="display: flex;">
		
			<div style="width: 600px;">
				<!-- {{ is_show}} -->

				<div style="width:600px;display: flex;align-items: center;justify-content: space-between;">
					<div>
						<b>{{ videoName }}</b>
						<el-tag type="success">作者：{{ videoUpusername }}</el-tag>
					</div>
					<div>
						<div class="heart-container" title="Like">
							<input type="checkbox" class="checkbox" id="Give-It-An-Id" v-model="collectionButton" @change="handleCheckboxChange">
							<div class="svg-container">
								<svg viewBox="0 0 24 24" class="svg-outline" xmlns="http://www.w3.org/2000/svg">
									<path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Zm-3.585,18.4a2.973,2.973,0,0,1-3.83,0C4.947,16.006,2,11.87,2,8.967a4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,11,8.967a1,1,0,0,0,2,0,4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,22,8.967C22,11.87,19.053,16.006,13.915,20.313Z">
									</path>
								</svg>
								<svg viewBox="0 0 24 24" class="svg-filled" xmlns="http://www.w3.org/2000/svg">
									<path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Z">
									</path>
								</svg>
								<svg class="svg-celebrate" width="100" height="100" xmlns="http://www.w3.org/2000/svg">
									<polygon points="10,10 20,20"></polygon>
									<polygon points="10,50 20,50"></polygon>
									<polygon points="20,80 30,70"></polygon>
									<polygon points="90,10 80,20"></polygon>
									<polygon points="90,50 80,50"></polygon>
									<polygon points="80,80 70,70"></polygon>
								</svg>
							</div>
						</div>
					</div>
				</div>


				

				<div class="divps" id="leve" v-show="is_show" style="width:600px;height:300px;">
					

					<video ref="video" id="media"  class="video-js" controls="container" controlsList='noplaybackrate nodownload'>
					</video>


					<!-- <video ref="video" id="media" width="600" height="300" controlsList='noplaybackrate' @timeupdate="timeupdate"  :src="src" controls="container" ></video> -->
				</div>
				<div class="divps" v-show="question_show">

					请回答下列问题：<br><br>
					<ul>
						<li v-for="i,x in question">

							{{x+1}}.{{ i.content }}<br>
							<van-radio-group v-model="checked[x]" direction="horizontal">
							<span v-for="j in i.answer">
								

								<br><van-radio  :name="j.id">{{j.text}}</van-radio>


							</span>
							</van-radio-group>
							<br><br>

						</li>
					</ul>

					<div class="demo-rate-block">
					    <span class="demonstration">打出您满意的分数:</span>
					    <el-rate v-model="value" :colors="colors" />
					  </div>
					<ul>
						<li v-for="i in ftext">错误问题：{{i["content"]}}<br>正确答案：<span style="color:red;">{{i["answer"]}}</span></li>
					</ul>

					<el-button @click="anew" color="#626aef" :dark="isDark" plain>重新观看</el-button>

					<el-button v-show="button_show" @click="postquestion" color="#626aef" :dark="isDark" plain>提交</el-button>
					<br><br><br><br>
					
				</div>

				 <el-dialog width="30%" v-model="dialogFormVisible" title="提示：">
				 	<span>您是否确认提交</span>
				 	<template #footer>
					      <span class="dialog-footer">
					        <el-button @click="dialogFormVisible = false">取消</el-button>
					        <el-button type="primary" @click="post_answer"
					          >确认</el-button
					        >
					      </span>
					    </template>

				 </el-dialog>


				<div class="comment" style="width: 751px;padding-left: 5px;">
					<comment/>
				</div>
			</div>

			<div style=" width: 300px; margin-left: 20px; margin-top: 20px;">
					<div style="padding-bottom: 10px;">

							<el-select v-model="seach" style="width:100%;" filterable placeholder="请输入选择点播视频">
								<el-option
								v-for="item in SearChList"
								:key="item.id"
								:label="item.name"
								:value="item.id"
								/>
									<!-- <el-option label="Zone one" value="shanghai" />
									<el-option label="Zone two" value="beijing" /> -->
						</el-select>

					</div>
						
						<!-- <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
					    <el-tab-pane label="突飞猛进值" name="first">
					    </el-tab-pane>
					    <el-tab-pane label="诲人不倦值" name="second">
					    	正在开发
					    </el-tab-pane>
				  	</el-tabs> -->
				  		<span style="color:#606266; padding-left:54px;padding-bottom: 10px;">学习成长榜</span>
				  	<el-table style="margin-top: 10px; width:100%;"  :data="data"  >
						<el-table-column  label="排名">
			                <template #default="scope">
			                		{{scope.row.id}}
			                </template>
			            </el-table-column>
			            <el-table-column prop="username" label="人员" />
			            <el-table-column prop="score" label="积分" />
					</el-table>
			</div>
			<!-- <div style="float:left; width: 284px; margin-left: 20px; margin-top: 20px;">
						
				  		<span style="color:#606266; padding-left:54px;padding-bottom: 10px;">诲人不倦榜</span>
				  		<el-table style="margin-top: 10px; width:200px;"  :data="datalist"  >
				  				
					    		<el-table-column width="60"  label="排名">
									<template #default="scope">
											{{scope.row.id}}
									</template>
			            		</el-table-column>
								<el-table-column prop="username" label="人员" />
								<el-table-column prop="points" label="积分" />
			          
					   </el-table>
					   
			</div> -->


			<van-overlay :show="loadshow" >
				<div class="wrapper">
					<div style="background-color: aliceblue;font-size: 18px">
						下载中：{{ downloadProgress }}%
					</div>
				</div>
			</van-overlay>
	</div>
</div>	
</template>
<script>
import { ElLoading } from 'element-plus'
import comment from './comment.vue'
import { showSuccessToast, showFailToast } from 'vant';
import qs from 'qs'

import videojs from 'video.js'
import 'video.js/dist/video-js.css';
import { PostVideoCollection,showColleciton } from "../utils/api"
import { mapGetters } from 'vuex';
import { h } from 'vue'
import { ElNotification } from 'element-plus'

import axios from 'axios'

import video_zhCN from 'video.js/dist/lang/zh-CN.json'
videojs.addLanguage('zh-CN', video_zhCN)



export default{
	components:{
		"comment":comment
	},

	data() {
		return{

			vid:"", //视频id
			src:"", //视频路径
			nid:"",
			is_show:true,   //视频状态
			question:"",    //问题
			checked:{},     //选项
			question_show:false,
			dialogFormVisible:false, //弹窗状态
			button_show:true, //按钮状态
			infolist:"", //观看过的视频
			colors : ['#99A9BF', '#F7BA2A', '#FF9900'],
			value:"",
			scorelist:"",
			currTime: null,
			maxTime:0,

			activeName:"",
			data:"",
			isIndex:1,
			datalist:"",
			//超级管理员
			superid:localStorage.getItem("uid"),

			text:"",
			show:false,
			ftext:"",

			SearChList:"",

			seach:"",

			// newSrc:"https://crnt-study-server.oss-cn-beijing.aliyuncs.com/video/111.mp4?OSSAccessKeyId=LTAI5tHZUE4QrYmuHyXb9DoJ&Expires=1699931241&Signature=ZwAZpqISHvUtyjKsL96pd5%2FhGI4%3D"

			baiduCode:'',

			Player:null,
			option:{
				language:"zh-CN",
				controls:true,
				fluid:true,
				notSupportedMessage:'暂时无法播放，请切换其他视频',
				mirror:true,
				playbackRates: [0.5, 1, 1.5, 2],
				techOrder: ['html5'],
				sources: [],
			},
			downloadName:"",
			collectionRemark:"",
			collectionRemarkShow:false,
			collectionButton:false,
			videoName:localStorage.getItem("videoName"),
			downloadProgress:0,
			loadshow:false,
			videoUpusername:""
		}

	},
	watch:{

		// seach:{
		// 	seach:function(newVal,oldVal){
		// 		this.resetSetItem('nid',newVal)
		// 	},
		// 	// deep:true,
		// 	// immediate:true,
		// // }

		superNid:function(newVal,oldVal){
			console.log("newVal",newVal)
			console.log("oldVal",oldVal)
			this.nid = newVal
			this.getVideo(this.nid)
		}

	},

	computed: {
    // 使用计算属性获取 nid
		...mapGetters(['getNid']),
		superNid() {
			return this.getNid;
		}
	},

	methods:{

		handleCheckboxChange(event) {
            console.log(event.target.checked)
			if(!event.target.checked){
				this.downCollerction(2)
			}else{
				this.downCollerction(1)
			}
			
        },

		// 视频收藏
		downCollerction(flag){
			PostVideoCollection({
				"vid":this.vid,"uid":localStorage.getItem("uid"),"flag":flag
			}).then(data=>{
				if(data.code == 200){
					if(flag == 2){
						showSuccessToast("成功取消收藏！")
					}else{
						showSuccessToast("请前往个人中心收藏夹中去查看")
					}
					
				}
			})
		},

		getBaiDuCode(){
			
			let key = 'aLq26biEwqBn2nk2LV9YsQi7tt4IG5GG';
			let url = 'http://192.168.2.66:8080/#/test';
			let scope = 'basic,netdisk';
			let client_secret = '8WKkYGgXzEgrgI5UDCPAd5NR03l3lMIG';
			var arr = location.href.split('?');
			if(!arr[1]){
				location.href = `http://openapi.baidu.com/oauth/2.0/authorize?scope=${scope}&client_id=${key}&response_type=code&redirect_uri=${url}`
			}else{
				
				this.baiduCode = arr[1].split('=')[1].split('#')[0]
				this.myaxios(`http://192.168.2.66:8080/opeAi/oauth/2.0/token?grant_type=authorization_code&code=${this.baiduCode}&client_id=${key}
					&client_secret=${client_secret}&redirect_uri=${url}
				`,'get').then((res)=>{
					console.log('res',res)
					if(res.access_token){
						localStorage.setItem('baiDuToken',res.access_token);
					}
				})
			}
		},
		getBaiduVideo(adToken){
			this.myaxios(`http://192.168.2.66:8080/apis/rest/2.0/xpan/file?method=streaming&access_token=${localStorage.getItem('baiDuToken')}
				&path=${encodeURIComponent('/测试目录/html标签学习.mp4')}&type=M3U8_AUTO_480&adToken=${encodeURIComponent(adToken)}
			`,'get').then((res)=>{
				if(res){

					// this.Player.src(res)
					// this.option.sources[0].src = 'http://192.168.2.66:8080/video/static/file.m3u8';
				}
			})
		},


		// 诲人不倦榜
		orderscore(){
			this.myaxios("/question/","delete",{"event":2})
			.then(data=>{
					var lists = data.data
					this.data = data.data
					for(let i in lists){

						lists[i]["id"] = Number(i)+1

					}
					this.datalist = lists

			})
		},
		handleClick(){
			

			this.myaxios("/ranklist/","post",{"uid":localStorage.getItem("uid")})
			.then(data=>{
					var lists = data.data;
					this.data = data.data;
					for(let i in lists){
						lists[i]["id"] = Number(i)+1
					}
					this.data = lists

			})
		},


		// 快进
		timeupdate(e) {
		    //   console.log(e.srcElement.currentTime - this.currTime);
		      if (e.srcElement.currentTime - this.currTime > 0.3) {
		        e.srcElement.currentTime = this.currTime>this.maxTime?this.currTime:this.maxTime;
		        // console.log("快进了");
		      }
		      this.currTime = e.srcElement.currentTime;
		      this.maxTime = this.currTime>this.maxTime?this.currTime:this.maxTime;
		    //   console.log("视频记录", e.srcElement.currentTime);
		    //   console.log("本地记录", this.currTime);
		},

		toggleCom(num) {
		    this.comNum = num;
		},


		postvideo:function(id){
			// console.log(id)
			// this.nid = id 
			// this.getVideo(id)
			showSuccessToast("您已观看过并全部回答正确")
			// this.$toast.success("您已观看过并全部回答正确")

		},

		// 已观看视频
		userVideo:function(){

			this.myaxios("/question/","delete",{"level":1})

			.then(data=>{
				this.infolist = data.data
			})

		},

		// 视频请求
		getVideo:function(nid){
			
			// 问题答案清空
			this.question = ""
			this.checked = {}
			this.value = ""
			this.maxTime=0
			this.currTime = null
			
			this.ftext=""
			this.videoUpusername = ""

			// 状态显示
			this.is_show = true
			this.question_show = false

			this.myaxios("/question/","get",{"uid":localStorage.getItem("uid"),"nid":nid}).then(data=>{
				this.vid = data.vid
				// this.src = "http://101.200.139.27:8000/static" + data.filename
				this.src = data.filename;
				this.downloadName = data.video_name
				this.videoUpusername = data.username

				// '/video/static/video/创想九周年2022p.mp4'
				// this.option.sources[0].src = data.filename.replace('http://101.200.139.27:8000','/video');
				this.Player.src({src:data.filename, type: 'video/mp4'})
				// console.log(this.Player)
				// setTimeout(() => {
				if(this.Player.play){
					this.Player.load();
					this.Player.play();
				}

				let _this = this;

				this.Player.on('ended', function() {
					showSuccessToast("播放完毕,请回答下列问题")
					// this.$toast.success("播放完毕,请回答下列问题")
					_this.is_show = false
					_this.question_show = true
					_this.button_show = true
					console.log("is_show--",this.is_show)
					// _this.$forceUpdate(); // 强制更新视图
				});
					
				// 	this.Player.load();
				// 	this.Player.play();
				// }, 3000);


				//任达
				// this.src = "http://127.0.0.1:8000/static" + data.filename

				// var time;
				// if(localStorage.getItem('baiDuToken')){
				// 	this.myaxios(`http://192.168.2.66:8080/apis/rest/2.0/xpan/file?method=streaming&access_token=${localStorage.getItem('baiDuToken')}
				// 		&path=${encodeURIComponent('/测试目录/html标签学习.mp4')}&type=M3U8_AUTO_480
				// 	`,'get').then((res)=>{
				// 		if(res.adToken){
				// 			time = setTimeout(async () => {
				// 				await this.getBaiduVideo(res.adToken)
				// 			}, res.ltime*1000);
				// 		}
				// 	})
				// }

				var questionlist
				questionlist = data.qlist
				for(let i=0;i<questionlist.length;i++){
					questionlist[i].answer = JSON.parse(questionlist[i].answer)
				}

				this.question = questionlist
				for(let x=0;x<data.leng;x++){
					this.checked[x] = 0
				}

				// this.userVideo()
				this.getscore()
				this.handleClick()

				// 获取视频是否被收藏
				showColleciton({uid:localStorage.getItem("uid"),"vid":data.vid}).then(data=>{
					if(data.data == 2){
						this.collectionButton=false
					}else{
						this.collectionButton=true
					}
					
				})

				this.videoName = localStorage.getItem("videoName")


			})

		},

		// 视频进度判断
		Playback_Progress(){
			

			// document.getElementById('media').addEventListener('progress', (e)=>{
			// 	console.log('提示视频正在下载中')
			// 	console.log(e)
			// })
			// console.log("xsx")
			// 监听 video 元素的 download 事件
			// document.getElementById('media').addEventListener('progress',  (event)=>{
			// 	// console.log(event)
			// 	// 检查点击事件是否来自下载按钮
			// 	if (event.target.tagName === 'A' && event.target.getAttribute('download') !== null) {
			// 		console.log('User clicked the download button');
			// 		// 在这里执行你想要的操作
					
			// 	}
			// });



			// if(this.is_show==true){
			// 	console.log("xsx")
			// 	document.getElementById('media').addEventListener("ended",()=>{
			// 		showSuccessToast("播放完毕,请回答下列问题")
			// 		// this.$toast.success("播放完毕,请回答下列问题")
			// 		this.is_show = false
			// 		this.question_show = true
			// 		this.button_show = true

			// 		// if(this.value != ""){
			// 		// 	this.put_score()
			// 		// }

					
			// 	})
			// }
		},
		// 诲人不倦值
		put_score(){
			this.myaxios("/score/","put",{"uid":localStorage.getItem("uid"),"score":this.value,vid:this.vid})
			.then(data=>{
				if(data.code == 403){
					// this.$message({ type: 'error', message: data.msg,showClose: true,duration:0 })
				}
				// console.log(data)
			})
		},

		// 重新观看
		anew(){

			this.getVideo(this.nid)
		},

		//非空验证
		postquestion:function(){

			// console.log(this.checked)

			for (var k in this.checked){
				
				if(this.checked[k] === 0){
					showFailToast("请填写完整答案")
					return
				}
								
			}

			if(this.value == ""){
				showFailToast("请为该视频打分")
				// this.$toast.fail("请为该视频打分")
				return

			}

			this.dialogFormVisible = true

		},


		// 积分增长
		post_score:function(){
			let _this = this
			this.myaxios("/score/","post",{"uid":localStorage.getItem("uid"),"vid":this.vid})
			.then(data=>{
				console.log("----------",data)
				if(data.code == 403){
					// this.$toast.fail(data.msg)
					console.log("xxxxssxsxsxsx")
					// showFailToast("工作期间不记录分数，下班后观看正常记录分数😅！")
					_this.$message({ type: 'error', message: data.msg,showClose: true,duration:0 })
				}else if(data.code == 409){
					showFailToast("工作期间不记录分数，下班后观看正常记录分数😅！")
					// this.$toast.fail("工作期间不记录分数，下班后观看正常记录分数😅！")
				}
			})

		},

		// 积分详情
		getscore:function(){
			this.myaxios("/score/","delete",{"uid":localStorage.getItem("uid"),"vid":this.vid})
			.then(data=>{
				this.scorelist = data.data
			})
		},


		//答案对比
		post_answer:function(){

			// alert(this.value)

			// 诲人不倦值上传
			this.put_score()

			var lists = [];
			// 构造问题
			for (var k in this.checked){

				for (var j in this.question){
					var dicts = {};

					dicts["id"] = this.question[k]["id"]
					dicts["answer"] = this.checked[k]	
				}
				lists.push(dicts)
			}
			
			this.dialogFormVisible = false
			// alert(lists)
				
			this.myaxios("/question/","post",{"qlist":JSON.stringify(lists),"uid":localStorage.getItem("uid")})
			.then(data=>{

				if(data.code === 200){
					this.dialogFormVisible = false
					showSuccessToast("恭喜！全部回答正确")
					// this.$toast.success("恭喜！全部回答正确")
					this.button_show = false

					//进入积分上传
					this.post_score()


					//进入下一阶段
					// this.Jump_stage()

					// 星值清空
					this.value = ""

				}else{
					showFailToast("抱歉，您没有全部回答正确，请重新观看视频。")
					// this.$toast.fail("“抱歉，您没有全部回答正确，请重新观看视频。”")
					// this.table_show = true
					// this.button_show = false
					this.ftext = data.data
					this.value = ""
					this.button_show = false

				}
				
			})			

		},

		getSearch:function(){
			this.myaxios("/supermenu/","put",{"uid":localStorage.getItem("uid")})
			.then(data=>{
				this.SearChList = data.data
			})
		},
		
		// 文件流下载
		downloadFile(data,type,name){
            let blob = new Blob([data], {type: `application/${type};charset=utf-8`});
            let downloadElement = document.createElement('a');
            let href = window.URL.createObjectURL(blob);
            downloadElement.href = href;
            downloadElement.download = name;
            document.body.appendChild(downloadElement);
            downloadElement.click();
            document.body.removeChild(downloadElement);
            window.URL.revokeObjectURL(href);
        },

		// 接收文件流
		GetVideoblod(){
			// const loading = ElLoading.service({
            //     lock: true,
            //     text: `下载中：${this.downloadProgress}`,
            //     background: 'rgba(0, 0, 0, 0.7)',
            // })
			this.loadshow = true
			this.axios({
				method:'post',
                url:'http://101.200.139.27:8000/UserDingLogin/',
                data:qs.stringify({"vid":this.vid,"uid":localStorage.getItem("uid")}),
				responseType:"blob",
				onDownloadProgress: (progressEvent) => {
					// 获取下载的总大小和已下载大小
					const total = progressEvent.total;
					const loaded = progressEvent.loaded;

					if (total) {
						const progress = Math.round((loaded / total) * 100); // 计算百分比
						console.log(`下载进度: ${progress}%`);
						// 在此处更新进度条或显示进度
						this.downloadProgress = progress;
						if(progress == 100){
							this.loadshow = false
						}
					}
				}
			}).then(({ data })=>{
				console.log("data--",data)
				this.downloadFile(data,"video/mp4",this.downloadName)
			})
		}

		
		
	},
	created(){
		console.log("superNid == ",this.superNid)
		this.nid = this.superNid
		if(this.nid){
			this.getVideo(this.nid)
		}

	},
	async mounted(){

		
		// this.getSearch()
		this.$nextTick(()=>{
			this.Player = videojs(this.$refs.video,this.option)
			let _this = this;
			this.Player.ready(function() {
				var player = this;
				player.controlBar.addChild('Button', {
					// title: '下载',
					el: videojs.createEl('button', {
						title: '下载',
						className: 'vjs-icon-download-item vjs-fast-download-button vjs-control vjs-button',
						innerHTML: '<span>下载</span>'
					}),
					clickHandler: function() {
						let fileName = _this.src.split('/');
						console.log(_this.src)
						// '/video/static/video/创想九周年2022p.mp4'
						if(_this.src.includes('oss')){
							const x = new window.XMLHttpRequest();
							x.open('GET', _this.src, true);
							x.responseType = 'blob';
							x.onload = () => {
								console.log(x.response)
								const url = window.URL.createObjectURL(x.response);
								const a = document.createElement('a');
								a.href = url;
								a.download = `${localStorage.getItem('videoName')}.mp4`;
								a.click();
							};
							x.send();
						}else{
							_this.GetVideoblod()
						}

						let msg = `${localStorage.getItem("username")}在**学以致用**中下载了:\n\n${localStorage.getItem('videoName')}`
							axios({
								method:'GET',
								url:'http://101.200.139.27:8008/GeneralHandler/',
								params:{
									flag:"1",
									msg:msg
								}
							})
						
					}
				});
			});
		})

		ElNotification({
			title: '温馨提示',
			message: h('b', { style: 'color: teal' }, '如果视频无法播放建议您找视频作者找到原视频进行替换，替换可以获得诲人不倦积分😁😁'),
			duration:0
		})


	},
}

</script>
<style>

.media-dimensions.vjs-fluid:not(.vjs-audio-only-mode){
	padding-top: 0px !important;
	height: 100% !important;
}
/* .video-js .vjs-picture-in-picture-control{
	display: none !important;
} */
.vjs-icon-download-item{
	background: red !important;
}
</style>
<style scoped>

@import url("../assets/style.css");


/* video::-webkit-media-controls-timeline {
        display: none;
    } */

h4{
	color: red;
}

p{
	text-align: center;
	font-size: 50px;
}

.wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.divps{
	padding-top: 20px;
	padding-left: 5px;
	position:relative;
}
.divps video{
	width: 100%;
	height: 100%;
}

.el-dialog__body {

	text-align: center;
}

.heart-container {
  --heart-color: rgb(255, 91, 137);
  position: relative;
  width: 40px;
  height: 40px;
  transition: .3s;
}

.heart-container .checkbox {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 20;
  cursor: pointer;
}

.heart-container .svg-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.heart-container .svg-outline,
        .heart-container .svg-filled {
  fill: var(--heart-color);
  position: absolute;
}

.heart-container .svg-filled {
  animation: keyframes-svg-filled 1s;
  display: none;
}

.heart-container .svg-celebrate {
  position: absolute;
  animation: keyframes-svg-celebrate .5s;
  animation-fill-mode: forwards;
  display: none;
  stroke: var(--heart-color);
  fill: var(--heart-color);
  stroke-width: 2px;
}

.heart-container .checkbox:checked~.svg-container .svg-filled {
  display: block
}

.heart-container .checkbox:checked~.svg-container .svg-celebrate {
  display: block
}

@keyframes keyframes-svg-filled {
  0% {
    transform: scale(0);
  }

  25% {
    transform: scale(1.2);
  }

  50% {
    transform: scale(1);
    filter: brightness(1.5);
  }
}

@keyframes keyframes-svg-celebrate {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    filter: brightness(1.5);
  }

  100% {
    transform: scale(1.4);
    opacity: 0;
    display: none;
  }
}

/*.comment{
	position: absolute;
    	top: 20%;
    	bottom: auto;
    	left: 0;
    	right: 0;
}*/

</style>